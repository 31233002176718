/*
Sample BodyStyle

{
                  "@id": "1",
                  "@Name": "Coupes"
                },

*/

// Hold the bodystyles list from the return ajax data
class hcpv_bodystyle{

    constructor(styleList) {

        this.styleList = styleList;
        this.stylesObj = {};

        for (var i = 0; i < styleList.length; i++) {
            this.stylesObj[styleList[i]["@id"]] = styleList[i]["@Name"];
        }

    }
    // Return a bodystyle with matching id
    getStyleNameById(id) {
        
        for(var i=0; i<this.styleList.length; i++)
        {
            if (this.styleList[i]["@id"] == id) { return this.styleList[i]["@Name"]; }
        }
        return null;
    }
    // Return a bodystyle with matching id
    getStyleNameById2(id) {
        return this.stylesObj[id]
    }

}